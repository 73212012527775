<template>
  <div class="view-wrapper landing-wrapper">
    <Navbar />
    <div class="collect-base-wrapper container">
      <p style="color:#F36633">Değerli hekimimiz;</p>
      <p>
        Canlı yayınımıza gösterdiğiniz ilgi ve katılımınız için teşekkür ederiz.
        </p><p>Sağlık Bakanlığı bildirimi sürecinde kullanılmak üzere aşağıdaki formu
        doldurmanızı rica ederiz.</p>
      </p>
      <form @submit="validateTCID" style="margin-bottom:30px;">
        <div class="alert alert-warning" role="alert">
          Lütfen ad, soyad ve doğum yılı bilgilerinizi kimliğinizde yazdığı
          şekilde giriniz.
        </div>
        <div class="form-group">
          <label for="name">Adınız</label>
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder
            v-model="form.name"
            required
          />
        </div>
        <div class="form-group">
          <label for="lastname">Soyadınız</label>
          <input
            type="text"
            class="form-control"
            id="lastname"
            placeholder
            v-model="form.lastName"
            required
          />
        </div>
        <div class="form-group">
          <label for="birthYear">Doğum Yılınız</label>
          <input
            type="text"
            class="form-control"
            id="birthYear"
            placeholder="Örn: 1980"
            v-model="form.birthYear"
            required
          />
        </div>
        <div class="form-group">
          <label for="tcId">TC Kimlik No</label>
          <input
            type="text"
            class="form-control"
            id="tcId"
            placeholder
            v-model="form.tcId"
            required
          />
        </div>
        <p>
          Şirketimiz GlaxoSmithKline İlaçları San. ve Tic. A.Ş. (“GSK”)
          tarafından sağlanan web tabanlı toplantı hizmeti kapsamında; 03 Temmuz
          2015 tarihli Resmi Gazetede yayınlanan “Beşeri Tıbbi Ürünlerin Tanıtım
          Faaliyetleri Hakkında Yönetmelik” çerçevesinde:
        </p>
        <div class="form-check">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              v-model="form.kvkk1"
              required
            />
            <label class="custom-control-label" for="customCheck1">
              <p>
                Yukarıda ve bundan sonraki ekranlarda beyan ettiğim şahsi
                bilgilerim ile ilgili, Türkiye İlaç ve Tıbbi Cihaz Kurumu
                (“TİTCK”)’nun veri tabanında başvuru, giriş ve bildirim
                işlemlerin yapılmasına ve bu platforma paylaşılmasına; ve bu
                kapsamda sağladığım tüm kişisel verilerimin kaydedilmesine,
                işlenmesine, gerekli olduğu hallerde üçüncü kişilere transfer
                edilmesine 5 yıl süre ile saklanılmasına; açık rıza verdiğimi
                kabul ve beyan ederim. Kişisel Verilerin Korunmasına İlişkin Aydınlatma ve Rıza
                Metni’ne <a
                href="/img/W2W Kayıt Ekranı Onam Formu v2.pdf"
                target="_blank"
                >buraya tıklayarak</a
              > ulaşabilirsiniz.
              </p>
            </label>
          </div>
        </div>
<!--
        <div class="form-check">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck2"
              v-model="form.kvkk2"
              required
            />
            
            <label class="custom-control-label" for="customCheck2">
              <a
                href="/img/W2W Kayıt Ekranı Onam Formu v2.pdf"
                target="_blank"
                >Kişisel Verilerin Korunmasına İlişkin Aydınlatma ve Rıza
                Metni’ni</a
              >
              okudum, onaylıyorum.
            </label>
            
          </div>
          
        </div>
        -->
        <div class="form-group">
        <button
          type="submit"
          class="btn btn-info btn-lg float-right"
          style="min-width: 120px;"
        >
          Kaydet
        </button>
        </div>
      </form>
      <div
        class="modal fade"
        id="xModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Hata!</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ error }}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      form: {
        email: null,
        name: null,
        lastName: null,
        birthYear: null,
        tcId: null,
        deviceId: null
      },
      error: null
    };
  },
  methods: {
    validateTCID(event) {
      event.preventDefault();
      this.form.tcId = this.form.tcId.trim();
      this.$axios
        .post("/checkTcId", this.form)
        .then(res => {
          if (res.data.tcId == this.form.tcId.toLowerCase()) {
            this.$router.push({ name: "id-confirmation" });
          } else {
            this.error = "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.";
            $("#xModal").modal("show");
          }
        })
        .catch(error => {
          console.log(error);
          let status = error.response.data.status;
          if (status == "failed") {
            console.log(status);
            this.error =
              "TC Kimlik numaranız girilen bilgilerle uyuşmuyor. Lütfen ad, soyad ve doğum yılınızı kimliğinizde yazıldığı şekilde giriniz.";
            console.log($("#xModal"));
            $("#xModal").modal("show");
          }
        });
    }
  },
  created() {
    this.form["tcId"] = this.$root.tcId;
    this.form["deviceId"] = this.$root.deviceId;
  }
};
</script>

<style>
.collect-base-wrapper {
  width: 100%;
  max-width: 720px !important;
  margin: auto;
  margin-top: 32px;
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}
</style>
