<template>
  <div class="landing-content" style="padding: 32px 32px">
    <form @submit="register" style="overflow: auto">
      <div class="alert alert-warning" role="alert">
        Lütfen ad, soyad ve doğum yılı bilgilerinizi kimliğinizde yazdığı
        şekilde giriniz.
      </div>
      <div class="form-group">
        <label for="tcId">TC Kimlik No</label>
        <input type="text" class="form-control" id="tcId" placeholder v-model="form.tcId" required />
      </div>
      <div class="form-group">
        <label for="name">İsim</label>
        <input type="text" class="form-control" id="name" placeholder v-model="form.name" required />
      </div>
      <div class="form-group">
        <label for="lastname">Soyisim</label>
        <input type="text" class="form-control" id="lastname" placeholder v-model="form.lastName" required />
      </div>
      <div class="form-group">
        <label for="birthYear">Doğum yılı</label>
        <input type="text" class="form-control" maxlength="4" id="birthYear" placeholder="Ornek: 1980" v-model="form.birthYear" required />
      </div>

      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input type="email" class="form-control" id="inputEmail" aria-describedby placeholder v-model="form.email"
          required />
      </div>
      <!--
      <div class="form-group">
        <label for="title">Ünvan</label>
        <select class="form-control" v-model="form.title" required>
          <option value="Eczacı">Eczacı</option>
          <option value="Uzman Eczacı">Uzman Eczacı</option>
          <option value="Pratisyen Doktor">Pratisyen Doktor</option>
          <option value="Uzman Doktor">Uzman Doktor</option>
          <option value="Operatör Doktor ">Operatör Doktor</option>
          <option value="Yardımcı Doçent Doktor">Yardımcı Doçent Doktor</option>
          <option value="Doçent Doktor">Doçent Doktor</option>
          <option value="Profesör Doktor">Profesör Doktor</option>
        </select>
      </div>
      -->
      <div class="form-group">
        <label for="speciality">Branş</label>
        <select class="form-control" id="speciality" v-model="form.speciality" required>
          <option value="Aile Hekimleri">Aile Hekimleri</option>
          <option value="Acil Tıp">Acil Tıp</option>
          <option value="Pediatri">Pediatri</option>
          <option value="Dermatoloji">Dermatoloji</option>
          <option value="KBB">KBB</option>
          <option value="Enfeksiyon">Enfeksiyon</option>
          <option value="Allerji-İmmünoloji">Allerji-İmmünoloji</option>
          <option value="Dahiliye">Dahiliye</option>
          <option value="Göğüs Hastalıkları">Göğüs Hastalıkları</option>
          <option value="Nöroloji">Nöroloji</option>
          <option value="Psikiyatri">Psikiyatri</option>
          <option value="Üroloji">Üroloji</option>
          <option value="Eczacı">Eczacı</option>
        </select>
      </div>
      <!--
      <div class="form-group">
        <label for="city">Şehir</label>
        <select class="form-control" v-model="form.city" required>
          <option value="Adana">Adana</option>
          <option value="Adıyaman">Adıyaman</option>
          <option value="Afyon">Afyon</option>
          <option value="Ağrı">Ağrı</option>
          <option value="Aksaray">Aksaray</option>
          <option value="Amasya">Amasya</option>
          <option value="Ankara">Ankara</option>
          <option value="Antalya">Antalya</option>
          <option value="Ardahan">Ardahan</option>
          <option value="Artvin">Artvin</option>
          <option value="Aydın">Aydın</option>
          <option value="Balıkesir">Balıkesir</option>
          <option value="Bartın">Bartın</option>
          <option value="Batman">Batman</option>
          <option value="Bayburt">Bayburt</option>
          <option value="Bilecik">Bilecik</option>
          <option value="Bingöl">Bingöl</option>
          <option value="Bitlis">Bitlis</option>
          <option value="Bolu">Bolu</option>
          <option value="Burdur">Burdur</option>
          <option value="Bursa">Bursa</option>
          <option value="Çanakkale">Çanakkale</option>
          <option value="Çankırı">Çankırı</option>
          <option value="Çorum">Çorum</option>
          <option value="Denizli">Denizli</option>
          <option value="Diyarbakır">Diyarbakır</option>
          <option value="Düzce">Düzce</option>
          <option value="Edirne">Edirne</option>
          <option value="Elazığ">Elazığ</option>
          <option value="Erzincan">Erzincan</option>
          <option value="Erzurum">Erzurum</option>
          <option value="Eskişehir">Eskişehir</option>
          <option value="Gaziantep">Gaziantep</option>
          <option value="Giresun">Giresun</option>
          <option value="Gümüşhane">Gümüşhane</option>
          <option value="Hakkari">Hakkari</option>
          <option value="Hatay">Hatay</option>
          <option value="Iğdır">Iğdır</option>
          <option value="Isparta">Isparta</option>
          <option value="İçel">İçel</option>
          <option value="İstanbul">İstanbul</option>
          <option value="İzmir">İzmir</option>
          <option value="Kahramanmaraş">Kahramanmaraş</option>
          <option value="Karabük">Karabük</option>
          <option value="Karaman">Karaman</option>
          <option value="Kars">Kars</option>
          <option value="Kastamonu">Kastamonu</option>
          <option value="Kayseri">Kayseri</option>
          <option value="Kırıkkale">Kırıkkale</option>
          <option value="Kırklareli">Kırklareli</option>
          <option value="Kırşehir">Kırşehir</option>
          <option value="Kilis">Kilis</option>
          <option value="Kilis">Kocaeli</option>
          <option value="Konya">Konya</option>
          <option value="Kütahya">Kütahya</option>
          <option value="Malatya">Malatya</option>
          <option value="Manisa">Manisa</option>
          <option value="Mardin">Mardin</option>
          <option value="Muğla">Muğla</option>
          <option value="Muş">Muş</option>
          <option value="Nevşehir">Nevşehir</option>
          <option value="Niğde">Niğde</option>
          <option value="Ordu">Ordu</option>
          <option value="Osmaniye">Osmaniye</option>
          <option value="Rize">Rize</option>
          <option value="Sakarya">Sakarya</option>
          <option value="Samsun">Samsun</option>
          <option value="Siirt">Siirt</option>
          <option value="Sinop">Sinop</option>
          <option value="Sivas">Sivas</option>
          <option value="Şanlıurfa">Şanlıurfa</option>
          <option value="Şırnak">Şırnak</option>
          <option value="Tekirdağ">Tekirdağ</option>
          <option value="Tokat">Tokat</option>
          <option value="Trabzon">Trabzon</option>
          <option value="Tunceli">Tunceli</option>
          <option value="Uşak">Uşak</option>
          <option value="Van">Van</option>
          <option value="Yalova">Yalova</option>
          <option value="Yozgat">Yozgat</option>
          <option value="Zonguldak">Zonguldak</option>
        </select>
      </div>
      -->
      <button type="submit" class="btn btn-info btn-lg float-right" style="min-width: 120px; margin-top: 32px">
        İleri
      </button>
    </form>
    <div class="modal fade" id="xModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Hata!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ error }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errors from "@/errors"

export default {
  data() {
    return {
      form: {
        email: null,
        name: null,
        lastName: null,
        birthYear: null,
        tcId: this.$root.tcId,
        deviceId: null
      },
      error: null
    };
  },
  methods: {
    register(event) {
      event.preventDefault();
      this.$root.consentInfo = {
        tcId: this.form.tcId,
        name: this.form.name,
        lastName: this.form.lastName,
        birthYear: this.form.birthYear,
        email: this.form.email,
        title: this.form.title,
        speciality: this.form.speciality,
        city: this.form.city,
        eventId: this.$root.event.id,
        deviceId: this.$root.deviceId
      };

      this.$axios
        .post("/postRegister", this.$root.consentInfo)
        .then(res => {
          if (res.data.eventUrl) {
            window.location.href = res.data.eventUrl;
          } else {
            if (!res.data.regId) {
              this.error =
                "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.";
              $("#xModal").modal("show");
            } else {
              this.$root.regId = res.data.regId;
              this.$router.push({ name: "live" });
            }
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.data) {
            let status = error.response.data.message || error.response.data.status;
            if (status.includes(errors.INVALID_TCID.code)) {
              this.error = "TC Kimlik Numaranız dogrulanamadı. Lütfen kontrol ediniz.";
              $("#xModal").modal("show");
            } else if (status.includes(errors.SMM_REQUIRED.code)) {
              this.$router.push({ name: "smmrequired" });
            } else {
              this.error =
                "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.";
              $("#xModal").modal("show");
            }
          }
        });
    }
  },
  created() {
    this.form["tcId"] = this.$root.tcId;
    this.form["deviceId"] = this.$root.deviceId;
  }
};
</script>

<style>

</style>
