<template>
  <div class="">
    <div class="container-fluid event-nav">
      <div class="container-fluid cookie-message">
        Çerezlerin kullanımı hakkında daha fazla bilgi için lütfen
        <a
          href="https://www.cookies.gsk.com/tr/turkish?src=www.gsksaglik.com&amp;__cqcat=gsksaglikcom"
          >çerez politikamızı</a
        >
        inceleyin.
      </div>
      <div class="container-fluid top-nav">
        <div class="container">
          <button
            class="top-nav-item btn btn-link"
            href="#"
            @click="empLogin"
            v-if="!$root.ssoUser"
          >
            GSK Çalışan Girişi
          </button>
          <ul class="nav nav-tabs top-nav-item" v-if="$root.ssoUser">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                >{{ $root.ssoUser.name }} {{ $root.ssoUser.lastName }}</a
              >
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#" @click="logout">Logout</a>
              </div>
            </li>
          </ul>
          <a
            class="top-nav-item danger"
            href="https://gskpro.com/tr-tr/yan-etki-bildir/"
            target="_blank"
            >Yan Etki Bildir</a
          >
        </div>
      </div>
      <div class="container">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light justify-content-between d-flex"
        >
          <a class="navbar-brand" href="#">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/gsk_logo.svg"
              /></router-link>
            </div>
            <div class="component-content ml-2 d-none d-lg-block">
              <div class="richText-content">
                <p>
                  <span class="rte-medium-font"
                    >Türkiye’de faaliyet gösteren sağlık profesyonelleri
                    içindir</span
                  >
                </p>
                <p>
                  <span class="rte-logo-title">
                    Bu site tanıtım materyalleri içerir.
                  </span>
                </p>
              </div>
            </div>
          </a>
          <!--
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <a
                class="nav-item nav-link"
                target="_blank"
                href="https://gskpro.com/tr-tr/internal-redirects/urunler/"
                >Ürünler</a
              >
              <a
                class="nav-item nav-link"
                target="_blank"
                href="https://gskpro.com/tr-tr/tedavi-alanlari/"
                >Tedavi Alanları</a
              >
              <a
                class="nav-item nav-link"
                href="https://etkinlikler.gskpro.com/"
                >Etkinlikler</a
              >
              <a
                class="nav-item nav-link"
                target="_blank"
                href="https://gskpro.com/tr-tr/bize-ulasin/"
                >İletişim</a
              >
              <a
                class="nav-item nav-link"
                target="_blank"
                href="https://gskpro.com/tr-tr/covid-19-ile-ilgili-her-sey/"
                >COVID-19</a
              >
            </div>
          </div>
          -->
        </nav>
        <!--
      <div class="employee-login">
        <button class="btn btn-primary" @click="empLogin" v-if="!$root.ssoUser">
          GSK Çalışan Girişi
        </button>
        <ul class="nav nav-tabs" v-if="$root.ssoUser">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >{{ $root.ssoUser.name }}</a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="#" @click="logout">Logout</a>
            </div>
          </li>
        </ul>
      </div>
      --></div>
    </div>
  </div>
</template>
<script>
import "bootstrap/js/dist/dropdown";
export default {
  methods: {
    empLogin() {
      let ssoUrl =
        "https://federation.gsk.com/as/authorization.oauth2?client_id=LSSO&response_type=code&scope=role openid email DefaultAttr";
      if (this.$root.event) {
        window.location = ssoUrl + "&state=" + this.$root.event.id;
      } else {
        window.location = ssoUrl;
      }
    },
    logout(event) {
      event.preventDefault();
      sessionStorage.removeItem("reft");
      this.$root.ssoUser = null;
      window.location = "/";
    }
  },
  mounted() {
    window.$(".dropdown-toggle").dropdown();
    console.log(this.$root.ssoUser);
  }
};
</script>
<style lang="scss" scoped>
.cookie-message {
  background-color: #efefed;
  display: block;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 14px;
  color: #544f40;
  a {
    color: #00707e;
    font-weight: bold;
  }
}
.top-nav {
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 0px;
  padding-right: 0px;

  .container {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
  }
  .top-nav-item {
    border-left: 1px solid #dfdfdf;
    display: inline-block;
    padding: 8px 10px 8px 8px;
    font-size: 14px;
    border-bottom: none !important;

    &.danger {
      background-image: url("https://a-cf65.gskstatic.com/etc/designs/zg/hcpportal-r-4-1/desktop/img/error.png");
      background-repeat: no-repeat;
      border-right: 1px solid #dfdfdf;
      padding-left: 36px;
      background-position-x: 8px;
      background-position-y: center;
    }
  }
}

.nav-item.dropdown {
  border: none;
}
.nav-link {
  &.dropdown-toggle {
    padding: 0px;
    border: none;
  }
}
.event-nav {
  background-color: #fff;
  position: relative;
  box-shadow: 0px 1px 4px #e6e6e6;
  z-index: 1;
  padding-left: 0px;
  padding-right: 0px;
  -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
  .navbar {
    background-color: #fff !important;
  }
  .navbar-brand {
    font-size: 16px;
    display: flex;
    align-items: center;
    align-content: center;
  }
  .navbar-collapse {
    justify-content: flex-end;
    .nav-link {
      color: #544f40;
      padding-left: 14px;
      padding-right: 14px;
      .active {
      }
    }
    a {
      color: #544f40;
      font-weight: bold;
    }
  }
  .navbar-nav {
  }
}
.employee-login {
  position: absolute;
  right: 15px;
}
.component-content {
  color: #72635d;
  .rte-logo-title {
    font-size: 12px;
  }
  p {
    line-height: 16px;
    margin: 0;
  }
}
</style>
