const errors = {
  INVALID_TCID: {
    status: 400,
    code: "400001",
    message: "Invalid TCID",
  },
  SMM_REQUIRED: {
    status: 401,
    code: "401001",
    message: "SMM does not exists",
  },
  CONSENT_NOT_FOUND: {
    status: 401,
    code: "401002",
    message: "Consent is required",
  },
  EVENT_NOT_FOUND: {
    status: 404,
    code: "404001",
    message: "Event does not exists",
  },
  INTERNAL_SERVER_ERROR: {
    status: 500,
    code: "500001",
    message: "Internal Server Error",
  },
  ZOOM_REGISTRATION_ERROR: {
    status: 500,
    code: "500002",
    message: "Error registering for Zoom",
  },
};

  module.exports = errors;