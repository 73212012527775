<template>
  <div class="view-wrapper landing-wrapper">
    <Navbar />
    <div
      class="collect-base-wrapper container"
      style="padding-top: 60px; padding-bottom: 60px"
    >
      <div
        class="alert alert-success"
        style="text-align: center; padding-top: 30px; padding-bottom: 30px"
        role="alert"
      >
        <h2>Teşekkürler!</h2>
        <h5>Kaydınız alınmıştır.</h5>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer
  }
};
</script>

<style>
</style>