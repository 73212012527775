import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";
import SMMRequired from "../views/SMMRequired.vue";
import EventListBase from "../views/EventListBase.vue";
import EventBase from "../views/EventBase.vue";
import Event from "../views/Event.vue";
import Register from "../views/Register.vue";
import Branch from "../views/Branch.vue";
import NotReady from "../views/NotReady.vue";
import TCCollect from "../views/TCCollect.vue";
import IdCollectThankyou from "../views/IdCollectThankyou.vue";
import BranchList from "../components/BranchList.vue";
import Live from "../components/Live.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/onam",
    name: "id-collect",
    component: TCCollect
  },
  {
    path: "/id-confirmation",
    name: "id-confirmation",
    component: IdCollectThankyou
  },
  {
    path: "/",
    name: "Landing",
    redirect: "/branch-list",
    component: EventListBase,
    children: [
      {
        path: "/branch-list",
        name: "branchList",
        component: BranchList
      },
      {
        path: "/branch/:id",
        name: "branch",
        component: Branch
      }
    ]
  },
  {
    path: "/event/:id",
    name: "event-base",
    component: EventBase,
    children: [
      {
        path: "join",
        name: "event",
        component: Event
      },
      {
        path: "register",
        name: "register",
        component: Register
      },
      {
        path: "live",
        name: "live",
        component: Live
      },
      {
        path: "/notready",
        name: "notready",
        component: NotReady
      }
    ]
  },
  {
    path: "/",
    name: "smmrequired",
    component: SMMRequired
  }
];
/*
  {
    path: '/:id',
    component: EventBase,
    children: [{
        path: '/',
        name: 'landing',
        component: Landing
      },
      {
        path: 'consent',
        name: 'consent',
        component: Consent
      },
      {
        path: 'smmrequired',
        name: 'smmrequired',
        component: SMMRequired
      },

      {
        path: 'live',
        name: 'live',
        component: Live
      },
      /*
      {
        path: 'embed',
        name: 'embed',
        component: Embed
      },
      {
        path: 'speaker',
        name: 'speaker',
        component: Speaker
      }
      
    ]
    */

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL + "tr-tr/",
  routes
});

export default router;
