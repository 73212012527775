import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";
import VueCookies from "vue-cookies";

window.$ = window.jQuery = require("jquery");

Vue.config.productionTip = false;

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_SERVER
});

Vue.prototype.$axios = $axios;
Vue.use(VueCookies);
Vue.use(require("vue-moment"));

new Vue({
  data: {
    ssoUser: null,
    branchData: {
      1: {
        title: "Aile Hekimliği"
      },
      2: {
        title: "Acil Tıp"
      },
      3: {
        title: "Pediatri"
      },
      4: {
        title: "Dermatoloji"
      },
      5: {
        title: "Kulak Burun Boğaz"
      },
      6: {
        title: "Enfeksiyon Hastalıkları"
      },
      7: {
        title: "Alerji-İmmünoloji"
      },
      8: {
        title: "Dahiliye"
      },
      9: {
        title: "Göğüs Hastalıkları"
      },
      10: {
        title: "Nöroloji"
      },
      11: {
        title: "Psikiyatri"
      },
      12: {
        title: "Üroloji"
      },
      13: {
        title: "Eczacı"
      },
      14: {
        title: "İşyeri Hekimliği-Genel Tıp"
      },
      15: {
        title: "Onkoloji"
      },
      16: {
        title: "Kardiyoloji"
      },
      17: {
        title: "Dahiliye-Endokrinoloji"
      },
      18: {
        title: "Romatoloji"
      },
      19: {
        title: "Hemotoloji"
      },
      20: {
        title: "Gastroenteroloji"
      },
      21: {
        title: "Geriatri"
      },
      22: {
        title: "Nefroloji"
      },
      23: {
        title: "Göz Hastalıkları"
      },
    }
    /*
    branchData: {
      1: {
        title: "Solunum",
        bg: "solunum.png"
      },
      2: {
        title: "Alerji ve Enfeksiyon",
        bg: "alerji.png"
      },
      3: {
        title: "Aşı",
        bg: "asi.png"
      },
      4: {
        title: "Sinir Sistemi",
        bg: "sinir_sistemi.png"
      },
      5: {
        title: "Dermatoloji",
        bg: "derma.png"
      },
      6: {
        title: "HIV",
        bg: "hiv.png"
      },
      7: {
        title: "Üroloji",
        bg: "uroloji.png"
      },
      8: {
        title: "PAH",
        bg: "pah.png"
      }
    }
    */
  },
  router,
  render: h => h(App)
}).$mount("#app");
