<template>
  <div>
    <div class="page-header">
      <button class="btn" @click="backClick">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="bi bi-arrow-left"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
          />
        </svg>
        Geri
      </button>
      <h4>{{ $root.branchData[this.$route.params.id].title }}</h4>
    </div>
    <div class="event-list container">
      <router-link
        class="card-wrapper"
        :to="'/event/' + event.id + '/join'"
        v-for="event in eventList"
        :key="event.id"
      >
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ event.title }}</h5>

            <p class="card-text">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-calendar-event"
                  viewBox="0 0 16 16"
                  style="margin-top: -2px"
                >
                  <path
                    d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"
                  />
                  <path
                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
                  />
                </svg>
                {{ event.startAt | moment("DD.MM.YYYY HH:mm") }}</span
              ><br /><br />
              <span><b>Konuşmacılar:</b> {{ event.speakers }} </span>
              <span><b>Branşlar:</b> {{ getBranchStr(event) }}</span>
            </p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      eventList: []
    };
  },
  methods: {
    backClick() {
      window.history.back();
    },
    getBranchStr(event) {
      let branchStr = "";
      let bArray = event.branches.split(",");
      _.forEach(bArray, (v, i) => {
        branchStr += this.$root.branchData[v].title;
        if (i != bArray.length - 1) {
          branchStr += ", ";
        }
      });
      return branchStr;
    }
  },
  created() {
    this.eventList = _.filter(this.$root.eventList, o => {
      if (o.branches) {
        let bIds = o.branches.split(",");
        if (_.indexOf(bIds, this.$route.params.id) !== -1) {
          return true;
        }
      } else {
        return false;
      }
    });
  }
};
</script>

<style lang="scss">
.card-wrapper {
  margin-bottom: 16px;

  color: #878280;
  display: inline-block;
  width: 100%;
  &:hover {
    color: #15717d;
    text-decoration: none;
  }
  .card {
    border: 1px solid #ececec;
    &:hover {
      color: #15717d;
      text-decoration: none;
      box-shadow: inset 0px 0px 0px 2px #15717d;
    }
    .card-text {
      font-size: 14px;
      span {
        display: inline-block;
        width: 100%;
      }
    }
  }
}
</style>
