<template>
  <div class="view-wrapper landing-wrapper">
    <div class="bg-layer"></div>
    <Navbar />
    <div class="landing-content">
      <h6>Webinar Girişi</h6>
      <h2 class="event-title">{{$root.event.title}}</h2>
      <div class="content-container card">
        <div
          class="alert alert-warning"
          role="alert"
          style="margin-bottom:0px; padding:60px;"
        >Bu online toplantıya erişim için ıslak imzalı SMM onay formunuz olması gerekmektedir. Lütfen firma temsilciniz ile iletişime geçin.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar
  }
};
</script>

<style>
</style>