<template>
  <div class="view-wrapper landing-wrapper">
    <Navbar />
    <div class="event-base-wrapper">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer
  }
};
</script>

<style>
.event-base-wrapper {
  max-width: 960px;
  margin: auto;
  margin-top: 32px;
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}
</style>
