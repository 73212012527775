<template>
  <footer class="container-fluid pb-5">
    <div class="container">
      <div class="copy mb-5">
        <div class="row">
          <div class="col">
            <p>
              <span class="rte-small-font"
                >Bu websitesinin içeriği Türkiye’de yaşayan kullanıcılar için
                hazırlanmıştır.</span
              >
            </p>
            <p>
              <span class="rte-small-font"
                >Bu sitedeki bilgiler, bir hekim veya eczacıya danışmanın yerine
                geçemez. Daha fazla bilgi için bir hekime ve / veya bir eczacıya
                başvurunuz.</span
              >
            </p>
            <p>
              <span class="rte-small-font"
                >GlaxoSmithKline grup şirketleri adına ©2020 Glaxosmithkline
                İlaçları San. Ve Tic. A.Ş. (Mersis No: 0396005012900016) ve<br />
                Glaxosmithkline Tüketici Sağlığı A.Ş. (Mersis No:
                0396069279900011). - Tüm hakları saklıdır.</span
              >
            </p>
            <p>
              <span class="rte-small-font"
                >Levent Özdilek River Plaza No: 13 İç Kapı No: 61 Şişli, 34330 Beşiktaş/İstanbul
                <a
                  class="rte-request-a-contact"
                  href="tel:339 45 75GSK"
                  target="_blank"
                  data-brandid=" "
                  data-di-id="di-id-f00b64e1-9ff6c2f6"
                  >(0212) 339 44 00 </a
                >&nbsp;</span
              >
            </p>
            <p>
              <span class="rte-small-font"
                >Türkiye Call Center:
                <a
                  data-brandid=" "
                  href="tel:444 5 GSK (444 5 475)"
                  class="rte-request-a-contact"
                  target="_blank"
                  data-di-id="di-id-839fb9b8-bdedbc28"
                  >444 5 GSK (444 5 475)</a
                ></span
              >
            </p>
            <p>
              <span class="rte-small-font"
                >Site Sorumlusu:
                <a
                  href="mailto:emre.x.biberoglu@gsk.com"
                  data-brandid="none"
                  data-gzgevent="none"
                  data-di-id="di-id-235d9048-782a245"
                  >Emre Biberoğlu</a
                ></span
              >
            </p>
          </div>
        </div>
      </div>

      <div class="row footer-section section-2">
        <div class="col-5">
          <div class="richText-content">
            <p class="mb-3">
              <span class="rte-large-font-italic">Daha fazlası için</span>
            </p>
            <p>
              <span
                >Ürünler, canlı yayınlar, tedavi alanları ve her konuda detaylı
                bilgi almak için bizimle iletişime geçebilirsiniz.</span
              >
            </p>
            <h4 tabindex="0">
              <a
                href="https://gskpro.com/tr-tr/bize-ulasin/"
                title="BİZE ULAŞIN"
                class="btn btn-primary mt-4"
                target="_blank"
                >İletişime geçin</a
              >
            </h4>
          </div>
          <p style="margin-top: 32px; font-size: 12px">NP-TR-NA-WCNT-230004</p>
        </div>
        <div class="col-3">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <a href="https://gsk.com.tr" target="_blank">Gsk.com.tr</a>
            </li>
          </ul>
        </div>
        <div class="col-3">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <a href="http://health.gsk.com/" target="_blank">Ülke Seçimi</a>
            </li>
            <li class="list-group-item">
              <a href="https://gskpro.com/tr-tr/sitemap/" target="_blank"
                >Site Haritası</a
              >
            </li>
            <li class="list-group-item">
              <a
                href="https://terms.gsk.com/tr-tr/pharmaceuticals/default/"
                target="_blank"
                >Kullanım Koşulları</a
              >
            </li>
            <li class="list-group-item">
              <a
                href="https://privacy.gsk.com/tr-tr/pharmaceuticals/default/"
                target="_blank"
                >Gizlilik Politikası</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
footer {
  background-color: #f0efed;
  margin: 0;
  margin-top: 32px;
  padding-top: 68px;
  a {
    font-weight: bold;
    font-size: 14px;
    &:hover {
      color: #15717e;
    }
  }
  .rte-small-font {
    font-size: 12px;
  }
  .rte-large-font-italic {
    font-style: italic !important;
    font-size: 40px !important;
    line-height: 40px !important;
    font-family: georgia !important;
    color: #72635d;
  }
  p {
    margin: 0;
  }

  .btn {
    border-radius: 32px;
    width: 80%;
    font-weight: bold;
  }

  .list-group-flush {
    .list-group-item {
      border: none;
      background-color: transparent;
      padding-bottom: 0px;
    }
  }
}
</style>