<template>
  <div class="view-wrapper landing-wrapper">
    <Navbar />
    <div class="event-base-wrapper container">
      <div class="spinner-border" role="status" v-if="!isReady && !isError">
        <span class="sr-only">Loading...</span>
      </div>
      <router-view v-if="isReady" />
      <div id="error-text" v-if="isError">{{errorMessage}}</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      isReady: false,
      isError: false,
      errorMessage: "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.",
      eventId: null
    };
  },
  components: {
    Navbar,
    Footer
  },
  created() {
    this.eventId = this.$router.currentRoute.params.id;

    let device = $cookies.get("device");
    let axiosParams = null;
    if (device) {
      axiosParams = {
        params: {
          deviceId: device
        }
      };
    }
    this.$axios.get("/events/" + this.eventId, axiosParams).then(event => {
      this.$root.event = event.data.event;
      if (event.data.deviceId) {
        $cookies.set("device", event.data.deviceId);
        this.$root.deviceId = event.data.deviceId;
      }

      if (this.$root.ssoUser) {
        this.$root.consentInfo = {
          tcId: this.$root.ssoUser.tcId,
          name: this.$root.ssoUser.name,
          lastName: this.$root.ssoUser.lastName,
          email: this.$root.ssoUser.email,
          eventId: this.$root.event.id,
          deviceId: this.$root.deviceId,
          token: sessionStorage.getItem("reft")
        };

        this.$axios
          .post("/postRegister", this.$root.consentInfo)
          .then(res => {
            // Event Url yoksa Live'a yönlendir. Varsa event Url'e
            if (res.data.eventUrl) {
              window.location.href = res.data.eventUrl;
            } else {
              if (!res.data.regId) {
                this.error =
                  "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.";
                $("#erorModal").modal("show");
              } else {
                this.isReady = true;
                this.$root.regId = res.data.regId;
                this.$router.push({ name: "live" });
              }
            }
          })
          .catch(error => {
            if (error.response.data) {
              let status = error.response.data.status;

              if (status == "failed") {
                this.errorMessage =
                  "Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.";
                this.isError = true

              }
            }
          });
      } else {
        this.isReady = true;
      }
    }).catch((e) => {
      if (e.response.status === 404) {
        this.errorMessage = "Hata! Etkinlik bulunamadı.";
      }
      this.isError = true

    });
  }
};
</script>

<style>
.event-base-wrapper {
  /*
  max-width: 960px;
  margin: auto;
  */
  margin-top: 32px;
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
}
</style>
