<template>
  <div id="app">
    <router-view v-if="isReady" />
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  name: "App",
  data() {
    return {
      isReady: false,
      event: null,
      pageSettings: null,
      tcId: null,
      consentInfo: null,
      isTCIDValid: false,
      isKVKKApproved: false,
      regId: null
    };
  },
  async created() {
    let axiosParams = {};
    /*
    this.$root.ssoUser = {
      name: "Cem Evren Ateş",
      email: "cem@livecast.com.tr",
      tcId: "cea14808"
    };
    */
    if (!this.$root.ssoUser) {
      if (this.$route.query.code) {
        /* access code gelmiş ise access code ile refresh token ve kullanıcı bilgileri*/
        axiosParams = {
          access_code: this.$route.query.code
        };
        await this.$axios.post("/post-auth", axiosParams).then(res => {
          let user = res.data.user;
          sessionStorage.setItem("reft", user.refresh_token);
          this.$root.ssoUser = user.data;
          if (this.$route.query.state) {
            window.location = "/event/" + this.$route.query.state + "/live";
          } else {
            window.location = "/";
          }
        });
      } else if (sessionStorage.getItem("reft")) {
        /* Kullanıcı yok ise local storage'ı kontrol et */
        axiosParams = {
          refresh_token: sessionStorage.getItem("reft")
        };
        await this.$axios.post("/post-auth", axiosParams).then(res => {
          let user = res.data.user;
          sessionStorage.setItem("reft", user.refresh_token);
          this.$root.ssoUser = user.data;
        });
      }
    }
    axiosParams = {
      params: {
        startAt_gt: moment()
          .subtract(4, "h")
          .format(),
        startAt_lt: moment()
          .add(4, "h")
          .format()
      }
    };
    await this.$axios.get("/events/", axiosParams).then(eventList => {
      this.$root.eventList = eventList.data;
      eventList.data.forEach(element => {
        let branchArray = _.trim(element.branches).split(",");
        this.$root.branchList = _.compact(
          _.union(this.$root.branchList, branchArray)
        );
      });
      if (
        this.$root.eventList.length === 1 &&
        this.$route.name !== "event" &&
        this.$route.name !== "id-collect"
      ) {
        this.$router.push({
          name: "branch",
          params: { id: this.$root.branchList[0] }
        });
      }
      this.isReady = true;
    });
  }
};
</script>

<style lang="scss">
#app {
}
.footer {
  position: absolute;
  bottom: 0;
  height: 56px;
}
</style>
