<template>
  <div class="view-wrapper landing-wrapper">
    <Navbar />
    <div class="landing-content"></div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar
  }
};
</script>

<style></style>
