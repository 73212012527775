"<template>
  <div>
    <div class="live-frame">
      <h2 class="event-title">{{ $root.event.title }}</h2>

      <p v-if="isReady && !playerUrl" style="width: 400px">
        Etkinlik henüz aktif değildir.
      </p>
      <div class="iframe-wrapper" v-if="isReady && playerUrl">
        <iframe
          width="100%"
          height="100%"
          :src="playerUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          scrolling="no"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Navbar from "@/components/Navbar.vue";
export default {
  components: {
    Navbar
  },
  data() {
    return {
      isTimeUp: false,
      openningTime: null,
      minutesLeft: null,
      interval: null,
      user: null,
      playerUrl: null,
      isReady: false
    };
  },
  created() {
    let regId = this.$root.regId || $cookies.get(this.$root.event.id);

    if (!regId) {
      this.$router.push("/" + this.$router.currentRoute.params.id);
    } else {
      $cookies.set(this.$root.event.id, regId);
    }
    this.$axios
      .post("/postParticipant", {
        regId: regId,
        deviceId: this.$root.deviceId
      })
      .then(res => {
        this.user = this.participant;

        if (res.data.playerUrl) {
          this.playerUrl =
            res.data.playerUrl +
            "?uid=" +
            res.data.participant.email +
            "&name=" +
            res.data.participant.name;
        }

        this.isReady = true;
      });
  }
};
</script>

<style lang="scss">
.live-frame {
  max-width: 854px;
  margin: auto;
  margin-top: 64px;
}
.iframe-wrapper {
  width: 100%;
  max-width: 854px;
  margin: auto;
  margin-bottom: 72px;
  background-color: transparent;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
  &::before {
    padding-top: calc(56.25% + 382px);
    content: "";
    display: block;
  }
}

iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>"
