<template>
  <div>
    <div class="branch-list container" v-if="!hasList">
      Bugün için planlanmış etkinlik bulunamadı.
    </div>
    <div class="branch-list container" v-if="hasList">
      <h4 class="mb-4">Lütfen branşınızı seçiniz</h4>
      <!--
    <router-link
      class="branch-wrapper"
      v-for="b in $root.branchList"
      :key="b"
      :to="/branch/ + b"
    >
      <div
        class="branch"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/' + $root.branchData[b].bg) + ')',
        }"
      >
        <span>{{ $root.branchData[b].title }}</span>
      </div>
    </router-link>
    -->
      <div class="list-group">
        <router-link
          class="branch-wrapper list-group-item list-group-item-action"
          active-class="active"
          tag="button"
          v-for="b in $root.branchList"
          :key="b"
          :to="/branch/ + b"
          >{{ $root.branchData[b].title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return { isReady: true, branchList: [] };
  },
  computed: {
    hasList() {
      if (this.$root.branchList) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {}
};
</script>

<style lang="scss">
.branch-list {
  width: 100%;
  max-width: 960px;
  min-height: 300px;
}
.branch {
  display: inline-block;
  height: 135px;
  width: 290px;
  span {
    position: relative;
    bottom: -96px;
    left: 16px;
    font-size: 20px;
    color: #fff;
  }
}
</style>
